#example-scroller-gallery {
  height: 800px;
  margin-top: 100px;
  position: relative;
  overflow: hidden;
}

#example-scroller-gallery #gallery-top-scrolling-row {
  padding-right: 150px;
  right: 0;
}

#example-scroller-gallery #gallery-top-scrolling-row .example-right-id, #example-scroller-gallery #gallery-top-scrolling-row .example-right-created {
  color: #fff;
}

#example-scroller-gallery #gallery-top-scrolling-row .example-right-bottom-title, #example-scroller-gallery #gallery-top-scrolling-row .example-owned-by {
  color: #000406;
}

#example-scroller-gallery #gallery-top-scrolling-row .example-property {
  color: #fbf6e0;
  background: #000406;
}

#example-scroller-gallery #gallery-bottom-scrolling-row {
  padding-left: 150px;
  top: 454px;
  left: 0;
}

#example-scroller-gallery #gallery-bottom-scrolling-row .example-right-id, #example-scroller-gallery #gallery-bottom-scrolling-row .example-right-created {
  color: #000406;
}

#example-scroller-gallery #gallery-bottom-scrolling-row .example-right-bottom-title, #example-scroller-gallery #gallery-bottom-scrolling-row .example-owned-by {
  color: #fbf6e0;
}

#example-scroller-gallery #gallery-bottom-scrolling-row .example-property {
  color: #000406;
  background: #fbf6e0;
}

#example-scroller-gallery .gallery-scrolling-row {
  z-index: 1;
  align-items: center;
  gap: 60px;
  transition: transform 1s cubic-bezier(.34, 1.56, .64, 1);
  display: flex;
  position: absolute;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example {
  gap: 30px;
  display: flex;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .vip-id-part {
  color: #9e7509;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .picture-cont {
  position: relative;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .picture-cont .picture-tag {
  color: #fff;
  width: 14px;
  word-break: break-all;
  text-align: right;
  font-family: Roboto Mono, monospace;
  font-size: 10px;
  line-height: 12px;
  position: absolute;
  top: 8px;
  right: 8px;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .picture-cont .picture-tag.inverted-color {
  color: #000406;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .picture-cont picture {
  width: 340px;
  height: 340px;
  border-radius: 10px;
  overflow: hidden;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right {
  width: 204px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-right-id {
  font-family: Roboto Mono, monospace;
  font-size: 42px;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-right-created {
  font-family: Oxygen, sans-serif;
  font-size: 10px;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-right-bottom-title {
  font-family: Righteous, sans-serif;
  font-size: 16px;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-owned-by {
  margin-top: 8px;
  margin-bottom: 25px;
  font-family: Oxygen, sans-serif;
  font-size: 10px;
  font-weight: bold;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-properties {
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 8px;
  display: flex;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-properties .example-property {
  height: 16px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  font-family: Oxygen, sans-serif;
  font-size: 10px;
  display: flex;
}

#example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-properties .example-property.first-100 {
  color: #9e7509 !important;
}

#gallery-white-bg {
  height: 464px;
  width: 100%;
  background: #fbf6e0;
  position: absolute;
  top: 120px;
}

@media (min-width: 3000px) {
  .gallery-scrolling-row {
    padding-left: unset !important;
    padding-right: unset !important;
    right: unset !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

@media (max-width: 1140px) {
  #example-scroller-gallery {
    height: 450px;
    margin-top: 30px;
  }

  #example-scroller-gallery #gallery-bottom-scrolling-row {
    top: 255px;
  }

  #example-scroller-gallery .gallery-scrolling-row {
    gap: 35px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  #example-scroller-gallery .gallery-scrolling-row .gallery-example {
    gap: 18px;
  }

  #example-scroller-gallery .gallery-scrolling-row .gallery-example .picture-cont .picture-tag {
    width: 10px;
    font-size: 6px;
    line-height: 7px;
    top: 6px;
    right: 6px;
  }

  #example-scroller-gallery .gallery-scrolling-row .gallery-example .picture-cont picture, #example-scroller-gallery .gallery-scrolling-row .gallery-example .picture-cont img {
    width: 195px;
    height: 195px;
  }

  #example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right {
    width: 120px;
  }

  #example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-right-id {
    font-size: 18px;
  }

  #example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-right-created {
    font-size: 8px;
  }

  #example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-right-bottom-title {
    font-size: 10px;
  }

  #example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-owned-by {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 8px;
  }

  #example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-properties {
    gap: 4px;
    margin-top: 4px;
  }

  #example-scroller-gallery .gallery-scrolling-row .gallery-example .gallery-example-right .example-properties .example-property {
    height: 12px;
    padding: 0 8px;
    font-size: 8px;
  }

  #gallery-white-bg {
    height: 265px;
    top: 70px;
  }
}

/*# sourceMappingURL=index.7f23c490.css.map */
