@import "constants";

$row-init-edge-padding: 150px;

#example-scroller-gallery {
  position: relative;
  margin-top: 100px;
  overflow: hidden;
  height: 800px;

  #gallery-top-scrolling-row {
    right: 0;
    padding-right: $row-init-edge-padding;

    .example-right-id,
    .example-right-created {
      color: white;
    }

    .example-right-bottom-title,
    .example-owned-by {
      color: $black;
    }

    .example-property {
      background: $black;
      color: $beige;
    }
  }

  #gallery-bottom-scrolling-row {
    left: 0;
    padding-left: $row-init-edge-padding;
    top: 454px;

    .example-right-id,
    .example-right-created {
      color: $black;
    }

    .example-right-bottom-title,
    .example-owned-by {
      color: $beige;
    }

    .example-property {
      background: $beige;
      color: $black;
    }
  }

  .gallery-scrolling-row {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 60px;
    transition: 1s transform cubic-bezier(0.34, 1.56, 0.64, 1);

    .gallery-example {
      display: flex;
      gap: 30px;

      .vip-id-part {
        color: $gold;
      }

      .picture-cont {
        position: relative;

        .picture-tag {
          position: absolute;
          top: 8px;
          right: 8px;
          color: white;
          font-family: $mono-font;
          font-size: 10px;
          line-height: 12px;
          width: 14px;
          word-break: break-all;
          text-align: right;

          &.inverted-color {
            color: $black;
          }
        }

        picture {
          width: 340px;
          height: 340px;
          border-radius: 10px;
          overflow: hidden;
        }
      }

      .gallery-example-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 204px;

        .example-right-id {
          font-family: $mono-font;
          font-size: 42px;
        }

        .example-right-created {
          font-family: $body-font;
          font-size: 10px;
        }

        .example-right-bottom-title {
          font-family: $header-font;
          font-size: 16px;
        }

        .example-owned-by {
          font-family: $body-font;
          font-size: 10px;
          font-weight: bold;
          margin-top: 8px;
          margin-bottom: 25px;
        }

        .example-properties {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
          margin-top: 8px;

          .example-property {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $body-font;
            font-size: 10px;
            height: 16px;
            padding: 0 12px;
            border-radius: 100px;

            &.first-100 {
              color: $gold !important;
            }
          }
        }
      }
    }
  }
}

#gallery-white-bg {
  position: absolute;
  top: 120px;
  height: 464px;
  width: 100%;
  background: $beige;
}

// do not do horizontal scroll for large monitors. just center everything
@media (min-width: 3000px) {
  .gallery-scrolling-row {
    padding-left: unset !important;
    padding-right: unset !important;
    right: unset !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

@media (max-width: $mobile-screen-width) {
  #example-scroller-gallery {
    margin-top: 30px;
    height: 450px;

    #gallery-bottom-scrolling-row {
      top: 255px;
    }

    .gallery-scrolling-row {
      padding-right: 20px !important;
      padding-left: 20px !important;
      gap: 35px !important;

      .gallery-example {
        gap: 18px;

        .picture-cont {
          .picture-tag {
            top: 6px;
            right: 6px;
            font-size: 6px;
            line-height: 7px;
            width: 10px;
          }

          picture,
          img {
            width: 195px;
            height: 195px;
          }
        }

        .gallery-example-right {
          width: 120px;

          .example-right-id {
            font-size: 18px;
          }

          .example-right-created {
            font-size: 8px;
          }

          .example-right-bottom-title {
            font-size: 10px;
          }

          .example-owned-by {
            margin-top: 0;
            margin-bottom: 8px;
            font-size: 8px;
          }

          .example-properties {
            margin-top: 4px;
            gap: 4px;

            .example-property {
              font-size: 8px;
              height: 12px;
              padding: 0 8px;
            }
          }
        }
      }
    }
  }

  #gallery-white-bg {
    height: 265px;
    top: 70px;
  }
}
